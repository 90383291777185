import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProgressLoaderComponent } from './progress-loader/progress-loader.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DurationPipe, KeysValuePipe, KioskTimezoneDatePipe, MinutePipe, MomentDatePipe, TimePipe } from '../common/pipes';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalComponent } from './modal/modal.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FieldsListComponent } from './fields-list/fields-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { EarningsOrdersToggleComponent } from './earings-orders-toggle/earnings-orders-toggle.component';

@NgModule({ declarations: [
        FooterComponent,
        NavbarComponent,
        ProgressLoaderComponent,
        ModalComponent,
        KeysValuePipe,
        TimePipe,
        MinutePipe,
        DurationPipe,
        MomentDatePipe,
        KioskTimezoneDatePipe,
        FieldsListComponent,
        EarningsOrdersToggleComponent
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        ProgressLoaderComponent,
        ModalComponent,
        KeysValuePipe,
        TimePipe,
        MinutePipe,
        DurationPipe,
        MomentDatePipe,
        KioskTimezoneDatePipe,
        FieldsListComponent,
        EarningsOrdersToggleComponent
    ], imports: [CommonModule,
        RouterModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        MatProgressBarModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        SweetAlert2Module.forRoot(),
        AngularMultiSelectModule,
        MatTooltipModule,
        NgxDatatableModule,
        NgSelectModule,
        TimepickerModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ComponentsModule {
}
