import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-earnings-orders-toggle',
    templateUrl: './earnings-orders-toggle.component.html',
})
export class EarningsOrdersToggleComponent implements OnInit {
    earningsMode = false;
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    setMode(earningsMode: boolean) {
        if (earningsMode != this.earningsMode) {
            this.earningsMode = earningsMode;
            this.onChange.emit(earningsMode);
        }
    }
}
