<div class=" btn-group btn-group-toggle float-right ml-2 mr-2"
		 data-toggle="buttons">
	<label
		class=" btn btn-xs btn-primary btn-simple"
		(click)="setMode(true)"
		[ngClass]="{ active: earningsMode === true }">
		<input checked="checked" name="options" type="radio"/>
		<span class=" d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Earnings
                  </span>
		<span class=" d-block d-sm-none">
                    <i class=" tim-icons icon-single-02"> </i>
                  </span>
	</label>
	<label
		class=" btn btn-xs btn-primary btn-simple"
		(click)="setMode(false)"
		[ngClass]="{ active: earningsMode === false }">
		<input class=" d-none" name="options" type="radio"/>
		<span class=" d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Orders
                  </span>
		<span class=" d-block d-sm-none">
                    <i class=" tim-icons icon-tap-02"> </i>
                  </span>
	</label>
</div>
