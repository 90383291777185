import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { Observable } from 'rxjs';
import {
    DiagnosticsStepSequenceBean,
    HardwareInfoBean,
    IdBean,
    KioskBean,
    KioskInfoBean, KioskTokenBean,
    ManageKioskDescriptionBean,
    ResponseBean,
    ResponseItemBean,
    TabletConfigurationBean,
    TabletRegisterRequestBean
} from '../model/model';
import { TaskState } from '../model/store-manager/task-state';
import { Result } from '../model/store-manager/result';
import { Schedule } from '../model/store-manager/schedule';
import { HttpHeaders } from '@angular/common/http';
import { Task } from '../model/store-manager/task';

@Injectable()
export class StoreManagerService extends BaseRequestService {

    setKioskMaintenance(kioskId: number, on: boolean): Observable<ResponseBean> {
        const p = { on };
        return this.getWithKioskToken(kioskId, 'store_manager/kiosk/' + kioskId + '/maintenance', 1, p);
    }

    setDeviceDisabled(kioskId: number, deviceId: number, value: boolean): Observable<ResponseBean> {
        const p = { value };
        return this.get('store_manager/kiosk/' + kioskId + '/device/' + deviceId + '/disabled', p);
    }

    refillIngredient(kioskId: number, deviceId: number): Observable<ResponseBean> {
        return this.get('store_manager/kiosk/' + kioskId + '/device/' + deviceId + '/refill_ingredient');
    }

    cancelOrder(kioskId: number, orderId: number, reason: string, sendSMS: boolean = true): Observable<ResponseBean> {
        return this.getWithKioskToken(kioskId, `store_manager/order/${orderId}/cancel?send_sms=${sendSMS}&reason=${reason}`, 1);
    }

    reorderOrder(kioskId: number, orderId: number, reason: string): Observable<ResponseBean> {
        return this.getWithKioskToken(kioskId, `store_manager/order/${orderId}/reorder?reason=${reason}`, 1);
    }

    refundOrder(kioskId: number, orderId: number, reason: string, pinCode: string): Observable<ResponseBean> {
        return this.getWithKioskToken(kioskId, `store_manager/order/${orderId}/refund/${pinCode}/?reason=${reason}`, 1);
    }

    startNow(orderId: number): Observable<ResponseBean> {
        return this.get('store_manager/order/' + orderId + '/start_now');
    }

    getKiosks(): Observable<ResponseItemBean<KioskBean[]>> {
        return this.get('store_manager/kiosk/list');
    }

    getKiosksInfo(): Observable<ResponseItemBean<KioskInfoBean[]>> {
        return this.get('store_manager/kiosk/list_info');
    }

    private static baseUrl(id: number, path: string) {
        return 'admin/kiosk/' + id + '/manage/' + path;
    }

    getKioskDescription(id: number): Observable<ResponseItemBean<ManageKioskDescriptionBean>> {
        return this.get(StoreManagerService.baseUrl(id, 'description'));
    }

    getKiosk(id: number): Observable<ResponseItemBean<KioskBean>> {
        return this.getWithKioskToken(id, 'store_manager/kiosk/' + id, 1, null, false);
    }

    unlockJars(kiosk: KioskBean): Observable<ResponseBean> {
        return this.get('store_manager/kiosk/' + kiosk.id + '/unlock_jars');
    }

    getNotConnectedDevicesForKiosk(kioskId: number): Observable<ResponseItemBean<HardwareInfoBean[]>> {
        return this.get('store_manager/kiosk/' + kioskId + '/hardware/not_connected');
    }

    getStepList(kioskId: number): Observable<ResponseItemBean<DiagnosticsStepSequenceBean[]>> {
        return this.get('store_manager/kiosk/' + kioskId + '/step/list');
    }

    executeSequence(kioskId: number, sequenceId: number): Observable<ResponseBean> {
        return this.getWithKioskToken(kioskId, 'store_manager/kiosk/' + kioskId + '/step/' + sequenceId + '/execute?repeat_count=1', 1);
    }

    setDoorLocked(kioskId: number, locked: boolean): Observable<ResponseBean> {
        return this.getWithKioskToken(kioskId, `admin/kiosk/${kioskId}/door/set_locked?value=${locked}`, 1);
    }

    placeRandomOrders(kioskId: number, number: number, recipe: number): Observable<ResponseBean> {
        const p = { number, recipe };
        return this.getWithKioskToken(kioskId, 'store_manager/kiosk/' + kioskId + '/place_random_orders', 1, p);
    }

    registerTablet(body: TabletRegisterRequestBean): Observable<ResponseItemBean<TabletConfigurationBean>> {
        return this.post('tablet/register', body)
    }

    registerUser(): Observable<ResponseItemBean<KioskTokenBean[]>> {
        return this.get('kiosk/tokens')
    }

    sendAuthCode(body: { email?: string,  phoneNumber?: string}): Observable<ResponseItemBean<TabletConfigurationBean>> {
        return this.post('tablet/send_auth_code', body)
    }

    getMaintenanceTasks(kioskId: number, from: string, to: string): Observable<{ [key: string]: TaskState[] }> {
        return this.getWithKioskToken(kioskId, `store-manager/task/kiosk/${kioskId}/scheduled?client_type=manager&schedule_type=maintenance`, 2, {
            from,
            to
        }, false);
    }

    getTodayTasks(kioskId: number, schedule_type: string): Observable<TaskState[]> {
        return this.getWithKioskToken(kioskId, `store-manager/task/kiosk/${kioskId}/today?client_type=manager&schedule_type=${schedule_type}`, 2, null, false);
    }

    getTask(kioskId: number, id: number): Observable<Task> {
        return this.getWithKioskToken(kioskId, `store-manager/task/${id}`, 2);
    }

    startTest(kioskId: number, scheduleId: number, taskId: number, userName: string, body: {
        recorded_values: { [key: string]: string }
    }): Observable<ResponseBean> {
        return this.postWithKioskToken(kioskId, 2, `store-manager/test/start?kiosk_id=${kioskId}&schedule_id=${scheduleId}&task_id=${taskId}&user_name=${userName}`, body);
    }

    postResult(kioskId: number, body: Result): Observable<ResponseBean> {
        return this.postWithKioskToken(kioskId, 2, `store-manager/result`, body);
    }

    putNote(kioskId: number, taskId: number, date: string, body: string): Observable<ResponseBean> {
        return this.putWithKioskToken(kioskId, 2, `store-manager/task/${taskId}/kiosk/${kioskId}/notes?date=${date}`, body);
    }

    uploadFile(data: any, kiosk_id: number, fileName: string, fileId: number): Observable<ResponseItemBean<IdBean>> {
        if (!fileId) {
            return this.octetWithKioskToken(kiosk_id, `store-manager/result/file?kiosk_id=${kiosk_id}&file_name=${fileName}`, data);
        } else {
            return this.octetPutWithKioskToken(kiosk_id, `store-manager/result/file/${fileId}`, data);
        }
    }

    uploadImage(kioskId: number, body: string): Observable<ResponseItemBean<IdBean>> {
        return this.jpegWithKioskToken(kioskId, `store-manager/result/image?kiosk_id=${kioskId}`, body);
    }

    createSchedule(task: Schedule): Observable<ResponseItemBean<IdBean>> {
        return this.post2(`store-manager/schedule`, task);
    }

    getExecutionDetails(kiosk_id: number, schedule_id: number, task_id: number): Observable<Array<{
        date: string, log_message?: string, process: string, process_status: string
    }>> {
        return this.getWithKioskToken(kiosk_id, `store-manager/test/history`, 2, { kiosk_id, schedule_id, task_id });
    }

    getRobotServiceUrl(kiosk_id: number) {
        const url = 'https://l3-service-dot-pacific-apex-122622.appspot.com/get_robot_service_status'
        const body = JSON.stringify({ 'kiosk_id': `${kiosk_id}` })

        return this.http.post(url, body, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }), observe: 'response', responseType: 'text'
        })
    }

    getRobotServiceStatus(url: string) {
        return this.http.get<{ result: { [key: number]: boolean } }>(url.replace('http', 'https'))
    }

    login(kiosk_id: number, user_name: string): Observable<ResponseBean> {
        return this.getWithKioskToken(kiosk_id, `store-manager/login`, 2, { kiosk_id, user_name });
    }

    logout(kiosk_id: number, user_name: string): Observable<ResponseItemBean<{ url: string }>> {
        return this.getWithKioskToken(kiosk_id, `store-manager/logout`, 2, { kiosk_id, user_name });
    }
}
