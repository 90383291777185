import { share } from 'rxjs/operators';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { ProgressLoaderService } from './loader.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { KioskInfoBean } from '../model/model';
import { environment } from '../../environments/environment';

@Injectable()
export abstract class BaseRequestService {

    constructor(protected http: HttpClient, protected dataService: DataService,
        protected loaderService: ProgressLoaderService) {
    }

    private static startCount = 0;

    public static apiServerUrl(version: number): string {
        return `${environment.SERVER_URL}v${version}/`;
    }

    protected static buildOffsetParams(limit?: number, offset?: number): any {
        const p = {
            limit: undefined,
            offset: undefined
        };
        if (limit != null) {
            p.limit = limit.toString();
        }
        if (offset != null) {
            p.offset = offset.toString();
        }
        return p;
    }

    protected static timeParams(startTime: number, endTime: number) {
        const p = {
            startTime: undefined,
            endTime: undefined
        };
        if (startTime != null) {
            p.startTime = startTime * 1000;
        }
        if (endTime != null) {
            p.endTime = endTime * 1000;
        }
        return p;
    }

    protected static kioskParams(kiosks: KioskInfoBean[]) {

        let result = '';
        if (kiosks && kiosks.length > 0) {
            kiosks.sort((k1, k2) => {
                return k1.kioskId - k2.kioskId;
            });
            for (const kiosk of kiosks) {
                if (kiosk.kioskId !== 0) {
                    result += ('kioskId=' + kiosk.kioskId + '&');
                }
            }
        }
        result = result.replace(/&\s*$/, '');
        return result;
    }

    protected serverUrl(version: number): string {
        return BaseRequestService.apiServerUrl(version);
    }

    protected headers(kioskId?: number): HttpHeaders {
        const kiosk = this.dataService.getProvisioning()?.kiosks?.find(it => +it.kioskId === +kioskId)
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: kiosk?.token || DataService.getToken()
        })
    }

    protected jpegHeaders(kioskId?: number): HttpHeaders {
        const kiosk = this.dataService.getProvisioning()?.kiosks?.find(it => +it.kioskId === +kioskId)
        return new HttpHeaders({
            'Content-Type': 'image/jpeg',
            Authorization: kiosk?.token || DataService.getToken()
        })
    }

    protected octetHeaders(kioskId?: number): HttpHeaders {
        const kiosk = this.dataService.getProvisioning()?.kiosks?.find(it => +it.kioskId === +kioskId)
        return new HttpHeaders({
            'Content-Type': 'application/octet-stream',
            Authorization: kiosk?.token || DataService.getToken()
        })
    }

    protected jpeg<T>(url: string, body: any, version: number = 1, kioskId?: number): Observable<any> {

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable = this.http.post<T>(this.serverUrl(version) + url, body, {
            headers: this.jpegHeaders(kioskId)
        }).pipe(share());
        observable.subscribe(resp => {

            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

    protected octet<T>(url: string, body: any, version: number = 1, kioskId?: number): Observable<any> {
        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable = this.http.post<T>(this.serverUrl(version) + url, body, {
            headers: this.octetHeaders(kioskId),
            observe: 'events',
            reportProgress: true
        }).pipe(share());
        observable.subscribe(resp => {

            if (resp instanceof HttpResponse) {
                BaseRequestService.startCount--;
                if (BaseRequestService.startCount == 0) {
                    this.loaderService.done();
                }
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }


    protected octetPut<T>(url: string, body: any, version: number = 1, kioskId?: number): Observable<any> {
        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable = this.http.put<T>(this.serverUrl(version) + url, body, {
            headers: this.octetHeaders(kioskId),
            observe: 'events',
            reportProgress: true
        }).pipe(share());
        observable.subscribe(resp => {

            if (resp instanceof HttpResponse) {
                BaseRequestService.startCount--;
                if (BaseRequestService.startCount == 0) {
                    this.loaderService.done();
                }
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }


    protected binaryPut<T>(url: string, body: any, version: number = 1, kioskId?: number): Observable<T> {
        const options = { headers: this.jpegHeaders(kioskId) };

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<T> = this.http.put<T>(this.serverUrl(version) + url, body, options).pipe(share());
        observable.subscribe(data => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

    protected post2<T>(url: string, body: any): Observable<T> {
        return this.post(url, body, 2);
    }

    protected post<T>(url: string, body: any, version: number = 1, kioskId?: number): Observable<T> {
        const options = { headers: this.headers(kioskId) };

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<T> = this.http.post<T>(this.serverUrl(version) + url, body, options).pipe(share());
        observable.subscribe(data => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

    protected put2<T>(url: string, body: any): Observable<T> {
        return this.put(url, body, 2);
    }

    protected put<T>(url: string, body: any, version: number = 1, kioskId?: number): Observable<T> {
        const options = { headers: this.headers(kioskId) };

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<T> = this.http.put<T>(this.serverUrl(version) + url, body, options).pipe(share());
        observable.subscribe(data => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

    protected get2<T>(url: string, search?: any): Observable<T> {
        return this.get(url, search, 2);
    }

    protected getWithKioskToken<T>(kioskId: number, url: string, version: number, search?: any, showLoading: boolean = true): Observable<T> {
        return this.get(url, search, version, kioskId, showLoading);
    }

    protected putWithKioskToken<T>(kioskId: number, version: number, url: string, search?: any): Observable<T> {
        return this.put(url, search, version, kioskId);
    }

    protected postWithKioskToken<T>(kioskId: number, version: number, url: string, body?: any): Observable<T> {
        return this.post(url, body, version, kioskId);
    }

    protected jpegWithKioskToken<T>(kioskId: number, url: string, search?: any): Observable<T> {
        return this.jpeg(url, search, 2, kioskId);
    }

    protected octetWithKioskToken<T>(kioskId: number, url: string, search?: any): Observable<T> {
        return this.octet(url, search, 2, kioskId);
    }

    protected octetPutWithKioskToken<T>(kioskId: number, url: string, search?: any): Observable<T> {
        return this.octetPut(url, search, 2, kioskId);
    }


    protected get<T>(url: string, search?: any, version: number = 1, kioskId?: number, showLoading: boolean = true): Observable<T> {
        const options = { headers: this.headers(kioskId), params: search };

        if (showLoading) {
            BaseRequestService.startCount++;
            this.loaderService.start();
        }

        const observable: Observable<T> = this.http.get<T>(this.serverUrl(version) + url, options).pipe(share());
        observable.subscribe(data => {
            if (showLoading) {
                BaseRequestService.startCount--;
                if (BaseRequestService.startCount === 0) {
                    this.loaderService.done();
                }
            }
        }, error => {
            if (showLoading) {
                BaseRequestService.startCount--;
                if (BaseRequestService.startCount === 0) {
                    this.loaderService.done();
                }
            }
        });
        return observable;
    }

    protected blob(url: string, search?: any, version: number = 1): Observable<Blob> {
        const options = { headers: this.headers(), params: search, responseType: 'blob' as 'blob' };

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<Blob> = this.http.get(this.serverUrl(version) + url, options).pipe(share());
        observable.subscribe(data => {

            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

    protected del<T>(url: string, search?: any, version: number = 1): Observable<T> {
        const options = { headers: this.headers(), params: search };

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<T> = this.http.delete<T>(this.serverUrl(version) + url, options).pipe(share());
        observable.subscribe(data => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount === 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

}
