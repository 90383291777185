import { Injectable } from '@angular/core';
import { TabletConfigurationBean } from '../model/model';
import { HttpHeaders } from '@angular/common/http';

function generateUniqueDeviceId() {
    // Generate a random identifier (not guaranteed to be globally unique)
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const KEY_TABLET_CONFIG = 'KEY_TABLET_CONFIG';
const KEY_USER_NAME = 'KEY_USER_NAME';
const KEY_LAST_ACTION_TIME = 'KEY_LAST_ACTION_TIME';
const KEY_SELECTED_KIOSK = 'KEY_SELECTED_KIOSK';
const KEY_USER_TOKEN = 'KEY_USER_TOKEN';

@Injectable()
export class DataService {

    static requestHeaders: HttpHeaders;

    static UUID() {
        let deviceId = localStorage.getItem('KEY_DEVICE_ID');

        if (!deviceId) {
            deviceId = generateUniqueDeviceId();
            localStorage.setItem('KEY_DEVICE_ID', deviceId);
        }

        return deviceId
    }


    static getCompanyId(): number {
        return 5649391675244544;
    }

    saveProvisioning(config: TabletConfigurationBean) {
        window.localStorage.setItem(KEY_TABLET_CONFIG, JSON.stringify(config));
    }

    getProvisioning(): TabletConfigurationBean {
        return JSON.parse(window.localStorage.getItem(KEY_TABLET_CONFIG));
    }

    saveUserName(config: string) {
        window.localStorage.setItem(KEY_USER_NAME, config);
    }

    getUserName(): string {
        return window.localStorage.getItem(KEY_USER_NAME);
    }

    setLastActionTime(time: number) {
        window.localStorage.setItem(KEY_LAST_ACTION_TIME, '' + time);
    }

    getLastActionTime(): number {
        return +(window.localStorage.getItem(KEY_LAST_ACTION_TIME) ?? 0);
    }

    clear() {
        window.localStorage.removeItem(KEY_TABLET_CONFIG);
        window.localStorage.removeItem(KEY_USER_NAME);
        window.localStorage.removeItem(KEY_LAST_ACTION_TIME);
        window.localStorage.removeItem(KEY_SELECTED_KIOSK)
    }

    logout() {
        window.localStorage.removeItem(KEY_USER_NAME);
        window.localStorage.removeItem(KEY_LAST_ACTION_TIME);
        window.localStorage.removeItem(KEY_SELECTED_KIOSK)
        window.localStorage.removeItem(KEY_USER_TOKEN)
    }

    getSelectedKioskId() {
        return +(window.localStorage.getItem(KEY_SELECTED_KIOSK) ?? 0);
    }

    setSelectedKioskId(id: number) {
        window.localStorage.setItem(KEY_SELECTED_KIOSK, '' + id);
    }

    static setToken(token: string) {
        window.localStorage.setItem(KEY_USER_TOKEN, token);
    }

    static getToken(): string {
        return window.localStorage.getItem(KEY_USER_TOKEN) || '';
    }

}
